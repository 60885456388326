//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReadyUploadSource from '@/components/readyUploadSource';
import RichText from '@/components/richText';
import Preservation from '@/components/preservation';
export default {
  components: {
    ReadyUploadSource,
    RichText,
    Preservation,
  },
  data() {
    return {
      //是否可以编辑
      is_edit: !0,
      id: '',
      frameTime: '',
      activeName: '1',
      init1: !0,
      init2: !0,
      init3: !0,
      freightList: [],
      spec_group_tips: !1,
      rules: {
        classify_id: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur',
          },
        ],
        goods_name: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur',
          },
        ],
        goods_price: [
          {
            required: true,
            message: '请输入商品价格',
            trigger: 'blur',
          },
        ],
        goods_stock: [
          {
            required: true,
            message: '请输入商品库存',
            trigger: 'blur',
          },
        ],
        goods_prime_cost: [
          {
            required: true,
            message: '请输入商品原价',
            trigger: 'blur',
          },
        ],
        goods_picture: {
          type: 'array',
          required: true,
          message: '请添加商品图片',
          trigger: 'change',
        },
        goods_details: [
          {
            required: true,
            message: '请填写商品详情',
            trigger: 'blur',
          },
        ],
      },
      sell_time: '',
      specGroup: [], //规格组
      specList: [], //规格列表
      specName: [],
      goodsimgList: [],
      goodsClassvalue: [],
      isShowMark: !1, //是否设置角标
      addImgAction: !1,
      //复用参数
      specList_form: {
        price: '',
        stock: '',
        kg: '',
        prime_cost: '',
        coding: '',
        picture: '',
      },
      goodsForm: {
        classify_id: '', //商品分类ID
        rank: '', //排序
        goods_name: '', //商品名称
        goods_subtitle: '', //商品副标题
        goods_tag: '', //搜索关键词
        virtual_sales: '', //虚拟销量
        goods_picture: [], //商品图片
        is_mark: 1, //显示角标位置  0无，1左下角，2底部，3右下角，4整图
        mark: '', //角标图片
        goods_video: '', //视频地址
        goods_details: '', //商品详情
        is_recommend: 0, //是否推荐，0否，1是
        is_putaway: 1, //是否上架，0下架，1上架
        sell_time: '', //售卖时间
        freight_type: 2, //运费类型，0运费，1运费模板 2默认模板
        freight_id: '', //运费，运费模板ID
        freight_price: '', //运费价格
        goods_price: '', //商品价格
        goods_prime_cost: '', //商品原价
        goods_stock: '', //商品库存
        goods_kg: '', //商品重量
        goods_coding: '', //	商品编码
        is_spec: 0, //是否多规格
        spec: [], //多规格
        goods_spec: [], //规格信息
      },
      spec_batch: {
        price: '',
        stock: '',
        kg: '',
        prime_cost: '',
        coding: '',
      },
    };
  },
  watch: {
    goodsForm: {
      handler(val) {
        if (this.is_edit) {
          this.$store.state.utils.is_update = 1;
        }
      },
      deep: true,
    },
  },
  computed: {
    goodsClass() {
      return this.$store.getters.goodsTypeList;
    },
  },
  created() {
    let id = this.$route.query.id;
    if (id) {
      let utils = this.$store.state.utils;
      utils.page = Number(this.$route.query.page);
      utils.rows = Number(this.$route.query.rows);
      utils.is_record = !0;
      this.id = id;
      this.addImgAction = !0;
      this.getGoodsDetail();
    }
    if (this.$route.query.is_other) {
      this.is_edit = !1;
      this.id = this.$route.query.id;
      this.getGoodsDetail();
    }
    if (this.is_edit) {
      this.$store.dispatch('goods/getTypeList');
      this.getfreightList();
    }
  },
  methods: {
    //删除规格组
    removeSpecGroup(i) {
      this.$confirm('确认要删除规格信息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.specGroup.splice(i, 1);
        this.specList = [];
        this.handleGoupAdd(this.specGroup.length);
      });
    },
    //获取视频地址
    getVideoSource(val) {
      console.log(val);
      this.goodsForm.goods_video = val.path;
    },
    //
    removeVideoThis() {
      this.goodsForm.goods_video = '';
    },
    // 删除单张图片
    removeImageThis(index) {
      this.addImgAction = true;
      // 展示用的数组
      this.goodsimgList.splice(index, 1);
      // 调接口时使用的数组
      this.goodsForm.goods_picture.splice(index, 1);
    },
    closeAddStore() {
      this.showAddStore = !1;
    },
    //获取商品图片
    getGoodsImg(val) {
      this.addImgAction = true;
      this.goodsimgList.push(...val);
      let arr = [];
      for (let i in this.goodsimgList) arr.push(this.goodsimgList[i].path);
      this.goodsForm.goods_picture = arr;
    },
    // 拖动重新赋值数据
    changeOrder(list) {
      this.addImgAction = true;
      this.goodsimgList = list;
      let arr = [];
      for (let i in this.goodsimgList) arr.push(this.goodsimgList[i].path);
      this.goodsForm.goods_picture = arr;
    },
    setfreightChange() {
      this.goodsForm.freight_price = '';
      this.goodsForm.freight_id = '';
    },
    handleClassChange(val) {
      let arr = [];
      for (let i in val) {
        for (let y in val[i]) {
          arr.push(val[i][y]);
        }
      }
      arr = [...new Set(arr)];
      this.goodsForm.classify_id = arr.join();
    },
    //获取运费模板
    getfreightList() {
      this.$axios.post(this.$api.set.freightList, { rows: 10000 }).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          this.freightList = list;
        }
      });
    },
    //获取商品详情
    getGoodsDetail() {
      this.$axios.post(this.$api.goods.goodsInfo, { id: this.id }).then(res => {
        if (res.code == 0) {
          let spec = res.result.spec;
          let result = res.result;
          let goods_spec = res.result.goods_spec;
          let specList = this.specList;
          let goodsClass = this.goodsClass;
          let classify_id = [];
          for (let i in spec) {
            let obj = {
              name: '',
              detail: [],
            };
            obj.name = spec[i].spec_name;
            for (let y in spec[i].spec_value) {
              obj.detail.push({
                detail: spec[i].spec_value[y].spec_value,
              });
            }
            this.specGroup.push(obj);
          }
          for (let i in this.specGroup) this.specName.push(this.specGroup[i].name);
          for (let i in goods_spec) {
            let name = [];
            for (let y in goods_spec[i].sku) {
              name.push(goods_spec[i].sku[y].spec_value);
            }
            specList.push({
              form: goods_spec[i],
              name: name,
            });
          }
          for (let i in result.goods_pictures) {
            this.goodsimgList.push({
              path: result.goods_pictures[i],
              isSelect: !1,
            });
          }
          // result.goods_picture = []
          result.goods_picture = result.goods_pictures;
          if (result.freight_id == 0) result.freight_id = '';
          if (result.freight_type == 2) {
            result.freight_id = '';
            result.freight_price = '';
          }
          if (result.sell_time > 0) this.sell_time = new Date(result.sell_time * 1000);
          classify_id = result.classify_id.split(',').map(Number);
          let classify_id_arr = [];
          let classify_id_arr_arr = [];
          let classify_obj = [];
          //处理父级分类
          for (let i in goodsClass) {
            for (let y in classify_id) {
              if (goodsClass[i].id == classify_id[y]) {
                classify_id_arr.push(goodsClass[i].id);
                classify_id.splice(y, 1);
              }
            }
          }
          classify_id_arr = [...new Set(classify_id_arr)];
          for (let i in goodsClass) {
            for (let y in classify_id_arr) {
              if (goodsClass[i].id == classify_id_arr[y]) {
                let children_id = goodsClass[i].children_id || [];
                if (children_id.length > 0) {
                  for (let k in classify_id) {
                    let index = children_id.indexOf(classify_id[k]);
                    if (index > -1) {
                      classify_obj.push({
                        key: y,
                        id: children_id[index],
                        parent_id: classify_id_arr[y],
                      });
                    }
                  }
                }
              }
            }
          }
          for (let i in classify_id_arr) {
            classify_id_arr_arr.push([classify_id_arr[i]]);
          }
          for (let i in classify_obj) {
            classify_id_arr_arr[classify_obj[i].key].push(classify_obj[i].id);
            classify_id_arr_arr[classify_obj[i].key].push(classify_obj[i].parent_id);
          }
          for (let i in classify_id_arr_arr) {
            let len = classify_id_arr_arr[i].length;
            if (len > 1) {
              classify_id_arr_arr[i].splice(classify_id_arr_arr[i].length - 1, 1);
            }
          }
          for (let i in classify_id_arr_arr) {
            let len = classify_id_arr_arr[i].length;
            if (len > 2) {
              for (let y = 0; y < len; y += 2) {
                classify_id_arr_arr.push(classify_id_arr_arr[i].slice(y, y + 2));
              }
              classify_id_arr_arr.splice(i, 1);
            }
          }
          this.init1 = !1;
          this.init2 = !1;
          this.init3 = !1;
          if (this.specGroup > 1) this.handleGoupAdd(this.specGroup.length, 3, 1);
          this.goodsClassvalue = classify_id_arr_arr;
          this.goodsForm = result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //商品提交
    async goodsSubmit() {
      try {
        let that = this;
        let specList = [...this.specList];
        let specGroup = this.specGroup;
        let goodsForm = this.goodsForm;
        let spec = [];
        let goods_spec = [];
        if (this.id) {
          let goods_pictures = goodsForm.goods_pictures || [];
          if (this.addImgAction == false) goodsForm.goods_picture.unshift(...goods_pictures);
        }
        if (!goodsForm.classify_id) throw '请选择商品分类';
        if (!goodsForm.goods_name) throw '请填写商品名称';
        if (!goodsForm.goods_picture.length) throw '请添加商品图片';
        if (!goodsForm.goods_price) throw '请填写商品价格';
        if (!goodsForm.goods_prime_cost) throw '请填写商品原价';
        if (!goodsForm.goods_details) throw '请填写商品详情';
        if (goodsForm.is_spec) {
          if (this.spec_group_tips) throw '请填写完整的规格信息';
          //多规格数据处理
          for (let i in specGroup) {
            let obj = {
              spec_name: '',
              spec_value: [],
            };
            obj.spec_name = specGroup[i].name;
            for (let y in specGroup[i].detail)
              obj.spec_value.push({
                spec_value: specGroup[i].detail[y].detail,
              });
            spec.push(obj);
          }
          //添加规格参数
          for (let i in specList) goods_spec.push(specList[i].form);
          //添加规格详情
          for (let i in specList) {
            let arr = [];
            for (let y in specList[i].name) {
              let obj = {};
              obj.spec_value = specList[i].name[y];
              arr.push(obj);
            }
            goods_spec[i].sku = arr;
          }
          //检查规格参数
          for (let i in goods_spec) {
            for (let y in goods_spec[i]) {
              if (y == 'price' || y == 'stock') {
                if (goods_spec[i][y] == '') throw '请填写完整的规格信息';
              }
              if (y == 'picture') {
                if (goods_spec[i][y] == '') {
                  goods_spec[i][y] = goodsForm.goods_picture[0];
                }
              }
            }
          }
          this.goodsForm.spec = spec;
          this.goodsForm.goods_spec = goods_spec;
        } else {
          if (!goodsForm.goods_stock) throw '请填写商品库存';
        }
        //如果是编辑的时候并且添加了新的规格就把原来有id的清除
        if (!goods_spec.every(item => item.id)) {
          goods_spec.map(item => delete item.id);
        }
        delete goodsForm.goods_pictures;
        goodsForm.sell_time = this.sell_time / 1000;
        let url = this.id ? this.$api.goods.editGoods : this.$api.goods.addGoods;
        let addRequest = function () {
          that.$axios.post(url, that.goodsForm).then(res => {
            if (res.code == 0) {
              that.$message.success(`${that.id ? '商品编辑成功' : '商品添加成功'}`);
              that.$router.push('/goods/goodsList');
            } else {
              that.addImgAction = !0;
              that.$message.error(res.msg);
            }
          });
        };
        if (goodsForm.freight_type == 2) {
          let isSelect = await this.$axios.post(this.$api.goods.isSelect);
          if (isSelect.code == 0) {
            if (isSelect.result.is_exist) {
              addRequest();
            } else {
              this.goodsForm.freight_type = 0;
              this.goodsForm.freight_price = 0;
              addRequest();
            }
          } else {
            throw isSelect.msg;
          }
        } else {
          addRequest();
        }
      } catch (e) {
        this.addImgAction = !0;
        this.$message.warning(e);
      }
    },
    //批量输入
    confirmBatchSpec() {
      let spec_batch = this.spec_batch;
      let specList = this.specList;
      for (let i in spec_batch) {
        if (spec_batch[i]) {
          for (let y in specList) specList[y].form[i] = spec_batch[i];
        }
      }
      for (let i in spec_batch) spec_batch[i] = '';
    },
    //添加规格组
    addSpecGroup() {
      if (this.specGroup.length == 3) return this.$message.warning('最多只能添加3组规格');
      let obj = {
        name: '',
        detail: [
          {
            detail: '',
          },
          {
            detail: '',
          },
        ],
      };
      this.specGroup.push(obj);
      if (this.specGroup.length > 1) this.specList = [];
    },
    //添加规格详情
    addSpecDetail(i) {
      this.specGroup[i].detail.push({
        detail: '',
      });
      this.handleSpecGroupChange(3);
    },
    //删除规格详情
    removeSpecDetail(i, y) {
      this.$confirm('确认要删除规格信息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.specGroup[i].detail.splice(y, 1);
        this.handleSpecGroupChange(5, i, y);
      });
    },
    //处理规格复用数据的添加
    handleGoupAdd(num, actions, type) {
      let specList = this.specList;
      let specGroup = this.specGroup;
      let length = specGroup.length;
      let [arr1, arr2, arr3, list] = [[], [], [], []];
      this.specName = [];
      if (num == 1) {
        arr1 = specGroup[0].detail;
        for (let i in arr1) {
          let obj = {
            name: [],
            form: Object.assign({}, this.specList_form),
          };
          obj.name.push(arr1[i].detail);
          specList.push(obj);
        }
      }
      if (num == 2) {
        arr1 = specGroup[0].detail;
        arr2 = specGroup[1].detail;
        for (let i in arr1) {
          for (let y in arr2) {
            let obj = {
              name: [],
              i: i,
              y: y,
              form: Object.assign({}, this.specList_form),
            };
            obj.name.push(arr1[i].detail);
            obj.name.push(arr2[y].detail);
            list.push(obj);
          }
        }
        if (actions == 3) {
          if (type) {
            list.map((item, index) => {
              specList[index].i = item.i;
              specList[index].y = item.y;
            });
          }
          for (let i in list) {
            for (let y in specList) {
              //添加规格值时根据添加后的数据下标对比原先数据的下标把原先的from赋值给新的数据
              if (specList[y].i + specList[y].y == list[i].i + list[i].y) list[i].form = specList[y].form;
            }
          }
          this.specList = list;
        } else {
          this.specList.push(...list);
        }
      }
      if (num == 3) {
        arr1 = specGroup[0].detail;
        arr2 = specGroup[1].detail;
        arr3 = specGroup[2].detail;
        for (let i in arr1) {
          for (let y in arr2) {
            for (let k in arr3) {
              let obj = {
                name: [],
                i: i,
                y: y,
                k: k,
                form: Object.assign({}, this.specList_form),
              };
              obj.name.push(arr1[i].detail);
              obj.name.push(arr2[y].detail);
              obj.name.push(arr3[k].detail);
              list.push(obj);
            }
          }
        }
        if (actions == 3) {
          //添加规格值时根据添加后的数据下标对比原先数据的下标把原先的from赋值给新的数据
          if (type) {
            list.map((item, index) => {
              specList[index].i = item.i;
              specList[index].y = item.y;
              specList[index].k = item.k;
            });
          }
          for (let i in list) {
            for (let y in specList) {
              if (specList[y].i + specList[y].y + specList[y].k == list[i].i + list[i].y + list[i].k) list[i].form = specList[y].form;
            }
          }
          this.specList = list;
        } else {
          this.specList.push(...list);
        }
      }
      //取规格名称
      for (let i = 0; i < length; i++) {
        this.specName.push(specGroup[i].name);
      }
      this.rulesGroup();
    },
    //规格信息验证
    rulesGroup() {
      let specGroup = this.specGroup;
      for (let i in specGroup) {
        if (specGroup[i].name) {
          this.spec_group_tips = !1;
        } else {
          this.spec_group_tips = !0;
          return;
        }
        if (specGroup[i].detail.length) {
          for (let y in specGroup[i].detail) {
            if (specGroup[i].detail[y].detail) {
              this.spec_group_tips = !1;
            } else {
              this.spec_group_tips = !0;
              return;
            }
          }
        } else {
          this.spec_group_tips = !0;
          return;
        }
      }
    },
    /**
     * 规格组输入的变化
     * @param actions 操作方式 2.输入规格名称 3.添加规格值 4.输入规格值 5.删除规格值
     * @param index 规格组specGroup下标
     * @param indexs 规格组specGroup —> detail下标
     * @param val 规格值
     */
    handleSpecGroupChange(actions, index, indexs, val) {
      let specList = this.specList;
      let specGroup = this.specGroup;
      let length = specGroup.length;
      let arr1 = specGroup[0] ? specGroup[0].detail : null;
      let arr2 = specGroup[1] ? specGroup[1].detail : null;
      let arr3 = specGroup[2] ? specGroup[2].detail : null;
      switch (actions) {
        case 2: //输入规格名称
          //处理一组规格
          if (length == 1) {
            //判断是否第一次添加
            if (this.init1) {
              this.init1 = !1;
              this.handleGoupAdd(1);
            } else {
              //取规格名称
              this.specName = [];
              for (let i = 0; i < length; i++) {
                this.specName.push(specGroup[i].name);
              }
            }
          } else {
            if (this.init2) {
              this.init2 = !1;
              this.handleGoupAdd(length);
            } else {
              //判断每个规格组有没有规格值
              let is_val = !0;
              for (let i in specGroup) {
                if (!specGroup[i].detail.length) {
                  is_val = !1;
                  break;
                }
              }
              this.specName = [];
              if (is_val) {
                //有规格值得全部修改
                for (let i = 0; i < length; i++) {
                  this.specName.push(specGroup[i].name);
                }
              } else {
                //没有规格值的不修改
                for (let i in specGroup) {
                  if (specGroup[i].detail.length) {
                    this.specName.push(specGroup[i].name);
                  }
                }
              }
            }
          }
          break;
        case 3:
          if (length == 1) {
            if (!this.init1) {
              if (specList.length) {
                specList.push({
                  name: [''],
                  form: Object.assign({}, this.specList_form),
                });
              }
            }
          } else if (length == 2) {
            this.init1 = !1;
            this.init2 = !1;
            this.handleGoupAdd(2, 3);
          } else if (length == 3) {
            this.init1 = !1;
            this.init3 = !1;
            this.handleGoupAdd(3, 3);
          }
          break;
        case 4:
          //处理修改规格值
          if (length == 1) {
            //修改1组
            if (!specList.length) {
              this.init1 = !1;
              this.handleGoupAdd(1);
            }
            specList[indexs].name = [val];
          } else if (length == 2) {
            //修改2组
            let nameList = [];
            for (let i in arr1) {
              if (arr2.length) {
                for (let y in arr2) {
                  let obj = { name: [] };
                  obj.name.push(arr1[i].detail);
                  obj.name.push(arr2[y].detail);
                  nameList.push(obj);
                }
              } else {
                let obj = { name: [] };
                obj.name.push(arr1[i].detail);
                nameList.push(obj);
              }
            }
            if (!specList.length) {
              this.init2 = !1;
              this.handleGoupAdd(2);
            }
            if (specList.length) {
              for (let i in nameList) specList[i].name = nameList[i].name;
            }
          } else if (length == 3) {
            //修改3组
            let nameList = [];
            for (let i in arr1) {
              for (let y in arr2) {
                if (arr3.length) {
                  for (let k in arr3) {
                    let obj = { name: [] };
                    obj.name.push(arr1[i].detail);
                    obj.name.push(arr2[y].detail);
                    obj.name.push(arr3[k].detail);
                    nameList.push(obj);
                  }
                } else {
                  let obj = { name: [] };
                  obj.name.push(arr1[i].detail);
                  obj.name.push(arr2[y].detail);
                  nameList.push(obj);
                }
              }
            }
            if (!specList.length) {
              this.init3 = !1;
              this.handleGoupAdd(3);
            }
            for (let i in nameList) {
              specList[i].name = nameList[i].name;
            }
          }
          break;
        case 5:
          var i_y = [];
          //处理删除规格值
          if (length == 1) {
            //删除1组
            this.specList.splice(indexs, 1);
          } else if (length == 2) {
            //删除2组
            if (index == 0) {
              let detail = specGroup[index].detail;
              //不是最后一组规格，如果删除完了就清除表单
              if (!detail.length) {
                this.specList = [];
                this.spec_group_tips = !0;
                return;
              }
              for (let k = 0; k < specList.length; k++) {
                if (specList[k].i == indexs) specList.splice(k--, 1);
              }
              //每次删除后重新更新下标
              if (arr1.length) {
                for (let i in arr1) {
                  if (arr2.length) {
                    for (let y in arr2) {
                      let obj = { i: i, y: y };
                      i_y.push(obj);
                    }
                  } else {
                    let obj = { i: i };
                    i_y.push(obj);
                  }
                }
                for (let i in i_y) {
                  specList[i].i = i_y[i].i;
                  if (arr2.length) specList[i].y = i_y[i].y;
                }
              }
            } else if (index == 1) {
              let detail = specGroup[index].detail;
              for (let k = 0; k < specList.length; k++) {
                if (specList[k].y == indexs) {
                  if (detail.length == 0) {
                    ////删除规格值时如果当前规格组的值是最后一个，不再对specList整条数据的删除而是删除specList[k].name进行删除
                    specList[k].name.splice(1, 1);
                  } else {
                    specList.splice(k--, 1);
                  }
                }
              }
              //每次删除后重新更新下标
              if (detail.length) {
                for (let i in arr1) {
                  if (arr2.length) {
                    for (let y in arr2) {
                      let obj = { i: i, y: y };
                      i_y.push(obj);
                    }
                  }
                }
                for (let i in i_y) {
                  specList[i].i = i_y[i].i;
                  specList[i].y = i_y[i].y;
                }
              }
              //删除规格值时如果当前规格组的值被删完了，表格的规格名称也进行对应删除
              if (!detail.length) this.specName.splice(1, 1);
            }
          } else if (length == 3) {
            //删除3组
            if (index == 0) {
              let detail = specGroup[index].detail;
              //不是最后一组规格，如果删除完了就清除表单
              if (!detail.length) {
                this.specList = [];
                this.spec_group_tips = !0;
                return;
              }
              for (let k = 0; k < specList.length; k++) {
                if (specList[k].i == indexs) specList.splice(k--, 1);
              }
              //每次删除后重新更新下标
              if (arr1.length) {
                for (let i in arr1) {
                  for (let y in arr2) {
                    if (arr3.length) {
                      for (let k in arr3) {
                        let obj = { i: i, y: y, k: k };
                        i_y.push(obj);
                      }
                    } else {
                      let obj = { i: i, y: y };
                      i_y.push(obj);
                    }
                  }
                }
                for (let i in i_y) {
                  specList[i].i = i_y[i].i;
                  if (arr2.length) specList[i].y = i_y[i].y;
                }
              }
            } else if (index == 1) {
              let detail = specGroup[index].detail;
              //不是最后一组规格，如果删除完了就清除表单
              if (!detail.length) {
                this.specList = [];
                this.spec_group_tips = !0;
                return;
              }
              for (let k = 0; k < specList.length; k++) {
                if (specList[k].y == indexs) specList.splice(k--, 1);
              }
              //每次删除后重新更新下标
              if (arr2.length) {
                for (let i in arr1) {
                  for (let y in arr2) {
                    if (arr3.length) {
                      for (let k in arr3) {
                        let obj = { i: i, y: y, k: k };
                        i_y.push(obj);
                      }
                    } else {
                      let obj = { i: i, y: y };
                      i_y.push(obj);
                    }
                  }
                }
                for (let i in i_y) {
                  specList[i].i = i_y[i].i;
                  specList[i].y = i_y[i].y;
                  if (arr3.length) specList[i].k = i_y[i].k;
                }
              }
              if (!specGroup[index].detail.length) this.specName.splice(1, 1);
            } else {
              let detail = specGroup[index].detail;
              for (let k = 0; k < specList.length; k++) {
                if (specList[k].k == indexs) {
                  if (detail.length == 0) {
                    specList[k].name.splice(2, 1);
                  } else {
                    specList.splice(k--, 1);
                  }
                }
              }
              //每次删除后重新更新下标
              if (arr3.length) {
                for (let i in arr1) {
                  for (let y in arr2) {
                    if (arr3.length) {
                      for (let k in arr3) {
                        let obj = { i: i, y: y, k: k };
                        i_y.push(obj);
                      }
                    } else {
                      let obj = { i: i, y: y };
                      i_y.push(obj);
                    }
                  }
                }
                for (let i in i_y) {
                  specList[i].i = i_y[i].i;
                  specList[i].y = i_y[i].y;
                  if (arr3.length) specList[i].k = i_y[i].k;
                }
              }
              if (!specGroup[index].detail.length) this.specName.splice(2, 1);
            }
          }
          break;
      }
      this.rulesGroup();
    },
  },
};
